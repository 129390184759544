import { DialogOverlay, DialogContent } from '@reach/dialog'
import { useEffect, useState } from 'react'
import { Image } from '@next/image'
import { Icon } from '~/elements/Icon/Icon'
import { useTranslation } from 'next-i18next'
import { animated } from '@react-spring/web'
import Cookies from 'js-cookie'
import { BanderoleNewsletter } from '../BanderoleNewsletter/BanderoleNewsletter'
import { useRouter } from 'next/router'

export const GeoLocPopup = () => {
  const router = useRouter()
  const { t } = useTranslation('geopopup')
  const countryCode = process.env.NEXT_PUBLIC_COUNTRY_CODE || 'DE'
  const [country, setCountry] = useState<string>('')
  const [forwardingUrl, setForwardingUrl] = useState<string>('')
  const [translatedShopName, setTranslatedShopName] = useState<string>('')
  const [visitShop, setVisitShop] = useState<string>('')
  const [proceedTo, setProceedTo] = useState<string>('')
  const [stayOnShop, setStayOnShop] = useState<string>('')
  const [showDialog, setShowDialog] = useState(false)
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false)
  //  const [newsletterPopupSession, setNewsletterPopupSession] = useState(false)
  const close = () => setShowDialog(false)

  const setGeoLocCookie = (isOnedayCookie: boolean) => {
    Cookies.set('geoLocPopup', 'true', {
      expires: new Date(
        new Date().getTime() + (isOnedayCookie ? 24 * 60 * 60 * 1000 : 864000 * 1000),
      ),
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
      path: '/',
    })
  }

  const closePopup = (isOnedayCookie: boolean) => {
    setGeoLocCookie(isOnedayCookie)
    close()
  }

  const closeNewsletterPopup = () => {
    //   setNewsletterPopupSession(true)
    setShowNewsletterPopup(false)
  }

  const fetchUserCountry = async () => {
    try {
      if (!(Cookies.get('geoLocPopup') === 'true')) {
        const countryResponse = await fetch('/api/country')
        if (countryResponse.ok) {
          const data = await countryResponse.json()
          const correctShopDomain = getCorrectShopDomain(data.countryCode.toLowerCase())
          const correctShopName = `benuta.${correctShopDomain}`
          const correctCountry = correctShopDomain !== 'eu' ? data.countryCode : 'EU'

          setCountry(correctCountry)
          setForwardingUrl(`https://www.${correctShopName}`)
          setTranslatedShopName(`go to ${correctShopName}`)
          setVisitShop(`VISIT ${correctShopDomain?.toUpperCase()}`)
          setProceedTo(`Yes, proceed to ${correctShopName}`)
          setStayOnShop(`Stay on benuta.${countryCode}.${correctShopDomain}`)

          if (data.countryCode !== countryCode && correctCountry !== countryCode)
            setShowDialog(true)
        }
      }
    } catch (error) {
      // Do nothing...
    }
  }

  const getCorrectShopDomain = (country: string) => {
    const store = process.env.NEXT_PUBLIC_STORE_CODE || 'benuta_de'
    let actualShop = 'eu'

    if (country === 'de' && store !== 'benuta_de') {
      actualShop = 'de'
    } else if (country === 'at' && store !== 'benuta_at') {
      actualShop = 'at'
    } else if (
      country === 'ch' &&
      !(store === 'benuta_chde' || store === 'benuta_chit' || store === 'benuta_chfr')
    ) {
      actualShop = 'ch'
    } else if (country === 'fr' && store !== 'benuta_fr') {
      actualShop = 'fr'
    } else if (country === 'nl' && store !== 'benuta_nl') {
      actualShop = 'nl'
    } else if (country === 'it' && store !== 'benuta_it') {
      actualShop = 'it'
    } else if (country === 'fr' && store !== 'benuta_fr') {
      actualShop = 'de'
    } else if (country === 'dk' && store !== 'benuta_dk') {
      actualShop = 'dk'
    } else if (country === 'pl' && store !== 'benuta_pl') {
      actualShop = 'pl'
    } else if (country === 'es' && store !== 'benuta_es') {
      actualShop = 'es'
    } else if (country === 'se' && store !== 'benuta_se') {
      actualShop = 'se'
    } else if (country === 'fi' && store !== 'benuta_fi') {
      actualShop = 'fi'
    } else if (country === 'cz' && store !== 'benuta_cz') {
      actualShop = 'cz'
    } else if (country === 'pt' && store !== 'benuta_pt') {
      actualShop = 'pt'
    } else if (country === 'no' && store !== 'benuta_no') {
      actualShop = 'no'
    } else if ((country === 'gb' || country === 'uk') && store !== 'benuta_co_uk') {
      actualShop = 'co.uk'
    }

    return actualShop
  }

  useEffect(() => {
    fetchUserCountry()
  }, [])
  /*
  useEffect(() => {
    const paths = ['/cart', '/account', '/login', '/newsletter', '/checkout']
    if (!newsletterPopupSession && !paths.some((path) => router?.asPath?.includes(path)))
      setShowNewsletterPopup(true)
  }, [router])
*/
  return (
    <>
      <section>
        <animated.div>
          <DialogOverlay
            className="z-50 overflow-hidden"
            isOpen={showDialog}
            onDismiss={() => closePopup(false)}
          >
            <DialogContent
              style={{ boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)' }}
              className="w-auto text-center lg:w-[550px]"
              aria-label="Geo Location Popup"
            >
              <div className="relative">
                <button
                  className="close-button absolute top-0 right-0 z-50 grid h-10 w-10 place-items-center rounded-full bg-grey-light"
                  onClick={() => closePopup(false)}
                  aria-label="Close Geo Location Popup"
                >
                  <Icon name="cross" />
                </button>
                <h2 className="text-center text-[24px]">{t(visitShop)}</h2>
                <p className="py-3.5 text-[16px]">
                  <span
                    className="inline-block py-3.5 text-[16px] font-extralight"
                    dangerouslySetInnerHTML={{ __html: t(translatedShopName) }}
                  />
                </p>
                <a
                  href={forwardingUrl}
                  className="relative mx-auto my-0 block flex h-[50px] w-auto flex-wrap content-center justify-center border border-grey-border border-opacity-50 font-extralight lg:block lg:w-[400px] lg:leading-[50px]"
                >
                  <Image
                    className="top-[18px] mr-[10px] inline lg:absolute lg:left-[25px] lg:mr-auto"
                    src={`/images/Flags/${country}.svg`}
                    placeholder="empty"
                    width={16}
                    height={16}
                    alt="flag-icon"
                  />
                  {t(proceedTo)}
                </a>
                <button
                  className="relative mx-auto my-0 block h-[50px] w-auto font-extralight lg:w-[400px]"
                  onClick={() => closePopup(true)}
                >
                  <Image
                    className="top-[17px] mr-[10px] inline lg:absolute lg:left-[26px] lg:mr-auto"
                    src={`/images/Flags/${countryCode}.svg`}
                    placeholder="empty"
                    width={16}
                    height={16}
                    alt="flag-icon"
                  />
                  {t(stayOnShop)}
                </button>
              </div>
            </DialogContent>
          </DialogOverlay>
        </animated.div>
      </section>
      <BanderoleNewsletter open={showNewsletterPopup} close={closeNewsletterPopup} />
    </>
  )
}

export default GeoLocPopup
